import React from 'react'
import DevopmentImg from './asset/images/background/blog-annex-2.png'
// import ProjectImg from './asset/images/icons/project-initiation.svg'
// import AnalysisImg from './asset/images/icons/analysis.svg'

function ProcessService() {
    return (
        <section className="fantasy_area process_service" id="Process">
            <div className="auto-container">
                <div className="sec-title centered">
                    <h2>Why Upgrade to GA4?</h2>
                    <p className="text mb-5">Upgrading to GA4 is an essential move for businesses seeking to leverage advanced insights and predictive analytics. It not only future-proofs your analytics setup in line with Google's focus, but also provides a more comprehensive view of customer behavior across multiple platforms. With GA4, you gain an edge in data-driven decision making in today's digital landscape.
                    </p>
                </div>
                <div className="row fantasy_inner mt-5 align-items-center">
                    <div className="col-xl-4 col-lg-4 col-md-12">
                        <div className="devopment_img"><img src={'blog-annex-2.png'} alt="Devopment Images" /> </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 fantasy_items">
                        <div className="col-md-12" >
                            <div className="fantasy fantasy-circle-style purple-hover">
                                <div className="dot-circle">
                                    <div className="effect-circle"></div>
                                    <div className="main-circle">
                                        <div className="circle-bg">
                                        <i className="fas fa-chart-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <h3>Advanced Insights</h3>
                                <p>GA4 offers comprehensive, cross-platform tracking, providing you with deeper insights into user behavior. It facilitates better understanding and decision-making based on user interactions with your website.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12" data-wow-delay="0.2s" >
                            <div className="fantasy fantasy-circle-style green-hover">
                                <div className="dot-circle">
                                    <div className="effect-circle"></div>
                                    <div className="main-circle">
                                        <div className="circle-bg">
                                        <i className="fas fa-analytics"></i>
                                        </div>
                                    </div>
                                </div>

                                <h3>Predictive Analytics</h3>
                                <p>Leveraging machine learning, GA4 predicts future actions of customers, such as likely churn or potential revenue, enabling proactive and strategic planning. It helps you focus your efforts where they're most likely to yield results.
</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 fantasy_items">
                        <div className="col-md-12" data-wow-delay="0.6s" >
                            <div className="fantasy fantasy-circle-style green-hover">
                                <div className="dot-circle">
                                    <div className="effect-circle"></div>
                                    <div className="main-circle">
                                        <div className="circle-bg">
                                            {/* <img src={AnalysisImg} alt="" /> */}
                                            <i className="fas fa-microchip"></i>
                                        </div>
                                    </div>
                                </div>
                                <h3>Future-Proof</h3>
                                <p>Google has announced the phasing out of Universal Analytics, making GA4 the future of Google's data measurement tools. Transitioning to GA4 now ensures you're ahead of the curve and well-prepared for future updates.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12" data-wow-delay="0.4s" >
                            <div className="fantasy fantasy-circle-style purple-hover">
                                <div className="dot-circle">
                                    <div className="effect-circle"></div>
                                    <div className="main-circle">
                                        <div className="circle-bg">
                                        <i className="fas fa-user-shield"></i>
                                        </div>
                                    </div>
                                </div>
                                <h3>Privacy-centric</h3>
                                <p>GA4 is designed with a strong focus on privacy, allowing you to respect user consent choices while still getting valuable insights. It uses advanced modeling to fill in the gaps when data is incomplete.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProcessService
