import React from 'react';
import Tilt from 'react-vanilla-tilt'
import ServiceRimg from './asset/images/background/s3-1.jpg'
import Background8 from './asset/images/background/1.png'
import Background9 from './asset/images/background/pattern-1.png'
import Background10 from './asset/images/background/pattern-2.png'
function Service() {
    const _tilt = {
        reverse: false,
        max: 2,
        perspective: 100,
        speed: 200,
        transition: true,
        axis: null,
        reset: true,
        easing: "cubic-bezier(.03,.98,.52,.99)",
        glare: false,
    }

    return (
        <section className="instructor-section" id="Services">
            <div className="auto-container">
            <div className="sec-title centered">
                <h2>Our GA4 Upgrade Service Includes</h2>
                <p className="text mb-5">Our GA4 upgrade services are built to ensure a streamlined transition with a focus on preserving your data's integrity. From a detailed audit to custom configurations and robust training, we provide an all-inclusive package to prepare your business for the future of analytics. Here's what our service includes:
                </p>
            </div>
            <div className="instructor-section">
                <div className="background-layer" style={{ backgroundImage: `url(${Background8})` }}></div>
                <div className="background-layer-one" style={{ backgroundImage: `url(${Background9})` }}></div>
                <div className="background-layer-two" style={{ backgroundImage: `url(${Background10})` }}></div>
                <div className="auto-container">

                    <div className="row clearfix">
                        {/* Blocks Column */}
                        <div className="blocks-column col-lg-8 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="row clearfix">

                                    {/* Service Block */}
                                    <div className="service-block  col-lg-6 col-md-6 col-sm-12">
                                        <Tilt options={_tilt} className="form_animations">
                                            <div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                <div className="border-layer"></div>
                                                <div className="icon-box">
                                                    <div className="icon flaticon-verify"><i className="fas fa-tasks"></i></div>
                                                </div>
                                                <h4><a >Detailed Analytics Audit</a></h4>
                                                <p className="text">Our expert team conducts a comprehensive audit of your current analytics setup, identifying potential improvements and ensuring a seamless transition to GA4 with data integrity in mind.</p>
                                            </div>
                                        </Tilt>
                                    </div>

                                    {/* Service Block */}
                                    <div className="service-block  col-lg-6 col-md-6 col-sm-12">
                                        <Tilt options={_tilt} className="form_animations">
                                            <div className="inner-box wow fadeInLeft" data-wow-delay="150ms" data-wow-duration="1500ms">
                                                <div className="border-layer"></div>
                                                <div className="icon-box">
                                                    <div className="icon flaticon-heart-box"><i className="fal fa-cogs"></i></div>
                                                </div>
                                                <h4><a>Custom GA4 Configuration</a></h4>
                                                <p className="text">We tailor your GA4 setup to your specific business needs, aligning it with your goals. Our custom configurations unlock GA4’s advanced features, offering you actionable insights for strategic decision making.

                                                </p>
                                            </div>
                                        </Tilt>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="instructor-column  col-lg-4 col-md-12 col-sm-12">
                            <div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <h4>Effective Training and Support</h4>
                                <p>We provide comprehensive training sessions to your team, ensuring proficient utilization of GA4. Plus, our ongoing support guarantees smooth operation and immediate resolution of any challenges.
                                </p>
                                <Tilt options={_tilt} className="form_animations mobile_hide">
                                    <img src={'s3-1.jpg'} alt="" />
                                </Tilt>
                                <div className="mobile_show">
                                    <img src={'s3-1.jpg'} alt="" />
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
            </div>
        </section>
    )
}

export default Service
