import React, { useState } from "react";
import Background12 from "./asset/images/icons/icon-1.png";
import Background14 from "./asset/images/background/pattern-19.png";
import Background15 from "./asset/images/background/pattern-1.png";
import ContactImg from "./asset/images/icons/annex-logo.png";
import ThanksImg from "../component/asset/images/background/cmpted_logo.a20dbd5e.jpg";
import { Link } from "react-router-dom";
import axios from "axios";
import HubspotForm from 'react-hubspot-form'

function Contact() {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // function submitForm(e) {
  //   e.preventDefault();
  //   const form = e.map(element =>    element.name);
  //   console.log("formProps", {e,form});
    // // Get the form input values
    // const firstName = form.elements["firstName"].value;
    // const lastName = form.elements["lastName"].value;
    // const email = form.elements["email"].value;
    // const message = form.elements["message"].value;
    // const mobile = form.elements["mobile"].value;
    // console.log(firstName, lastName, email, message, mobile);

  //   const postData = async () => {
  //     try {
  //       const response = await axios.post(
  //         "http://dashboardannexlogics.we4php.com/api/dashboardannexlogics/lead",{form},
  //         // { firstName, lastName, email, message, mobile },
  //         {
  //           mode: "no-cors",
  //         }
  //       )

  //       console.log("Response:", response.data);
  //       form.reset();
  //       setIsFormSubmitted(true);
  //       // Reset the form after successful submission
  //     } catch (error) {
  //       // Handle any errors that occurred during the request
  //       console.error("Error:", error.message);
  //       setIsFormSubmitted(false);
  //     }
  //   };
  //   postData();
  // }

  return (
    <section className="contact-section" id="Contact_Us">
      {/* Contact Section  */}
      <div
        className="pattern-layer"
        style={{ backgroundImage: `url(${Background14})` }}
      ></div>
      <div
        className="pattern-layer-two"
        style={{ backgroundImage: `url(${Background15})` }}
      ></div>
      <div
        className="icon-layer-two"
        style={{ backgroundImage: `url(${Background12})` }}
      ></div>
      <div
        className="icon-layer-three"
        style={{ backgroundImage: `url(${Background12})` }}
      ></div>
      <div className="auto-container">
        <div className="row clearfix">
          <div className="info-column col-lg-4 col-md-12 col-sm-12">
            <div className="inner-column">
              <div
                className="icon-layer"
                style={{ backgroundImage: `url(${Background12})` }}
              ></div>
              <div className="image">
                {/* <img src={ContactImg} alt="contact" /> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14228.443827183099!2d75.7272284!3d26.9316967!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5d2231a3da5%3A0x37cea97df57c2a15!2sAnnexLogics%20System%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1694606823790!5m2!1sen!2sin" title="annexlogics" style={{width:400, height:300,border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
              <ul className="contact-list">
                <li>
                  <i className="fal fa-map-marker"></i>
                  <span>
                    B-3, TC Nagar, Prithviraj Nagar, B Sector, Jaipur, Rajasthan
                    302012
                  </span>
                </li>
                <li>
                  <i className="fal fa-mobile-android"></i>
                  <a href="tel:+919462908999">+91-946-290-8999</a>
                </li>
                <li>
                  <i className="fal fa-envelope-open"></i>
                  <a href="mailto:info@annexlogics.com">info@annexlogics.com</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="form-column col-lg-8 col-md-12 col-sm-12 form_z">
            {isFormSubmitted ? (
              <div className="inner-column">
                <div className="default-form">
                  <div lg="6" md="12" className="col-lg-12 md-12 pl-0 pr-0">
                    <div className="thanks-img-wrapper">
                      <div className="thanks-img">
                        <img src={ThanksImg} className="img-fluid" alt="thanks" />
                      </div>
                      <div className="btns-box mt-5 mb-3">
                        <Link
                          className="theme-btn btn-style-one"
                          to="/"
                          spy={true}
                          smooth={true}
                          duration={1000}
                        >
                          <span
                            className="txt"
                            onClick={() => setIsFormSubmitted(false)}
                          >
                            Back To Home
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="inner-column project-about-form">
                <div className="color-layer"></div>

                <div className="sec-title">
                  <h2> Tell Us About Your Project </h2>
                </div>
                {/* <form
                  id="my_form"
                  // method="post"
                  // action="http://dashboardannexlogics.we4php.com/api/dashboardannexlogics/lead"
                  // onSubmit={(e) => submitForm(e)}
                >
                  <div className="row clearfix"> */}
                    {/* <div className="form-group col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        name="firstName"
                        placeholder="First Name *"
                        required
                      />
                    </div>
                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name *"
                        required
                      />
                    </div>
                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email *"
                        required
                      />
                    </div>
                    <div
                      className="form-group col-lg-12 col-md-12 col-sm-12"
                      hidden
                    >
                      <input name="mobile" value="1234567890" />
                    </div>

                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                      <textarea
                        placeholder="Enquiry *"
                        name="message"
                        required
                        style={{ height: 220 }}
                      ></textarea>
                    </div>

                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                      <button type="submit" className="theme-btn btn-style-one">
                        <span className="txt">Submit</span>
                      </button>
                    </div> */}
            
{/* 
                  </div>
                </form> */}
                    <HubspotForm
                      portalId='22249194'
                      formId='3c7647e8-7f15-4fd9-bc86-5329145f127a'
                      onSubmit={() => console.log()}
                      onReady={(form) => console.log('Form ready!')}
                      loading={<div>Loading...</div>}
                    />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* End Contact Section  */}
    </section>
  );
}

export default Contact;
