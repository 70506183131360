import React from 'react'

function DevelopmentService() {
    return (
        <section className="fantasy_area fa_2 fa_3">
            <div className="auto-container">
                <div className="sec-title centered">
                    <h2>Why Choose Annexlogics for Your GA4 Migration Services</h2>
                    <p className="text">Annexlogics Systems Pvt Ltd is a leading development and marketing agency with a passion for helping businesses thrive in the digital landscape. We have a proven track record of providing top-tier services that deliver results. Our expertise extends across a wide range of areas, including analytics, search engine optimization, digital marketing, and web development.
                    </p>
                </div>
                <div className="row fantasy_inner mt-5 justify-content-md-center">
                    <div className="col-xl-12 row fantasy_items">
                        <div className="col-md-6" >
                            <div className="fantasy">
                                <span className="icons"><i className="fal fa-phone-laptop"></i></span>
                                <h3>GA4 Implementation Expertise</h3>
                                <p>Annexlogics boasts a team of seasoned professionals who bring a wealth of experience in Google Analytics, both UA and GA4. We not only provide a seamless transition process, but we also empower your team with a comprehensive understanding of GA4's intricate workings, maximizing your data's potential.</p>
                            </div>
                        </div>
                        <div className="col-md-6"  >
                            <div className="fantasy fantasy_2">
                                <span className="icons"><i className="fal fa-code"></i></span>
                                <h3>Customized Approach</h3>                                <p>Our services are designed around you. We understand that every business has unique needs and goals. Hence, we meticulously tailor our GA4 upgrade services to align with your specific objectives. We help you harness the full potential of GA4’s sophisticated features, fueling your business growth.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6"  >
                            <div className="fantasy fantasy_3">
                                <span className="icons"><i className="fal fa-user-headset"></i></span>
                                <h3>Unmatched Support</h3>                                <p>Customer satisfaction is at the heart of what we do at Annexlogics. We believe in creating lasting relationships, which means we're always at your disposal for support and queries. With us by your side, you'll enjoy a smooth, hassle-free transition to GA4, and unparalleled ongoing assistance.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="fantasy fantasy_4">
                                <span className="icons">
                                {/* <i className="fal fa-user-headset"></i> */}
                                <i className="fal fa-check-double"></i>
                                </span>
                                <h3>Data-Driven Decisions</h3>
                                <p>At Annexlogics, we believe in the power of data. Our approach to GA4 upgrades is driven by deep analysis and insights to optimize your setup for the most accurate results. We don't just set you up with GA4; we equip you with the knowledge and tools to leverage the platform's advanced capabilities, driving improved decision-making and business growth.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DevelopmentService
