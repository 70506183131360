import React, { useState } from "react";

import Background from "./asset/images/background/pattern-15.png";
import Background2 from "./asset/images/main-slider/icon-2.png";
import Background3 from "./asset/images/main-slider/pattern-1.png";
import Background4 from "./asset/images/main-slider/pattern-2.png";
import Background5 from "./asset/images/main-slider/icon-1.png";
import Background6 from "./asset/images/icons/icon-3.png";
import Background7 from "./asset/images/icons/icon-4.png";
import ThanksImg from "./asset/images/background/banner-thanks.jpg";
import { Link } from "react-scroll";
// import axios from "axios";
import HubspotForm from 'react-hubspot-form'


function Banner() {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  // function submitForm(e) {
  //   console.log("formProps", e);
  //   e.preventDefault();
  //   const form = e.target;

  //   // Get the form input values
  //   const firstName = form.elements["firstName"].value;
  //   const lastName = form.elements["lastName"].value;

  //   const email = form.elements["email"].value;
  //   const message = form.elements["message"].value;
  //   const mobile = form.elements["mobile"].value;
  //   console.log(firstName, lastName, email, message, mobile);

  //   const postData = async () => {
  //     try {
  //       const response = await axios.post(
  //         "http://dashboardannexlogics.we4php.com/api/dashboardannexlogics/lead",
  //         { firstName, lastName, email, message, mobile },
  //         {
  //           mode: "no-cors",
  //         }
  //       );

  //       console.log("Response:", response.data);
  //       form.reset();
  //       setIsFormSubmitted(true);
  //       // Reset the form after successful submission
  //     } catch (error) {
  //       // Handle any errors that occurred during the request
  //       console.error("Error:", error.message);
  //       setIsFormSubmitted(false);
  //     }
  //   };
  //   postData();
  // }
  return (
    <section className="banner-section-two" id="Home">
      <div className="auto-container">
        <div
          className="pattern-layer-two"
          style={{ backgroundImage: `url(${Background})` }}
        ></div>
        <div
          className="pattern-layer-three"
          style={{ backgroundImage: `url(${Background2})` }}
        ></div>
        <div className="row clearfix clearfix_mobile">
          {/* Content Column */}
          <div className="content-column col-lg-6 col-md-12 col-sm-12">
            <div
              className="pattern-layer-one"
              style={{ backgroundImage: `url(${Background3})` }}
            ></div>
            <div
              className="icon-layer-one"
              style={{ backgroundImage: `url(${Background2})` }}
            ></div>
            <div
              className="icon-layer-three"
              style={{ backgroundImage: `url(${Background5})` }}
            ></div>
            <div
              className="icon-layer-four"
              style={{ backgroundImage: `url(${Background6})` }}
            ></div>
            <div className="inner-column ">
              <h1>
                Unlock the Power of Data with Google Analytics 4 Upgrade
                Services
              </h1>
              <p className="mt-5">
              Welcome to the new era of advanced analytics with Google Analytics 4 (GA4).
               As Google gradually phased out Universal Analytics on July 1, 2023,
                it's time for businesses to embrace this enhanced analytics model designed for the modern,
                 multi-screen world.
                <br />
                <br />
                GA4 brings you deeper, more actionable insights, helping you
                understand your customers' journey across different devices and
                platforms in real-time. By upgrading to GA4, you're not just
                adhering to Google's latest standard but also equipping your
                business with a powerful tool that delivers better integration
                with Google Ads, predictive analytics, and a more flexible
                approach to tracking user interactions.
                <br />
                <br />
                At Annexlogics Systems Pvt Ltd, we're here to simplify your
                transition from Universal Analytics (UA) to GA4, ensuring a
                smooth, effortless shift without compromising your data's
                integrity. Embrace the future of analytics with GA4 and
                experience a world of unparalleled insights and data-driven
                decision-making.
              </p>
              <div className="btns-box">
                <Link
                  className="theme-btn btn-style-one"
                  to="Contact_Us"
                  spy={true}
                  smooth={true}
                  duration={1000}
                >
                  {/* <span className="txt">Build a project with us</span> */}
                  <span className="txt">Connect for Migration</span>

                </Link>
              </div>
            </div>
          </div>

          {/* Image Column */}
          <div className="content-column col-lg-6 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="circle-layer"></div>
            </div>
            <div
              className="icon-layer-two"
              style={{ backgroundImage: `url(${Background4})` }}
            ></div>
            <div
              className="icon-layer-five"
              style={{ backgroundImage: `url(${Background7})` }}
            ></div>

            <div className="contact-form-wrapper">
              <div className="axil-contact-form contact-form-style-1">
                {isFormSubmitted ? (
                  <div lg="6" md="12" className="col-lg-12 md-12 pl-0 pr-0">
                    <div className="thanks-img-wrapper">
                      <div className="thanks-img">
                        <img src={ThanksImg} className="img-fluid" alt="thanks" />
                      </div>
                      <div className="btns-box mt-5 mb-3">
                        <Link
                          className="theme-btn btn-style-one"
                          to="/"
                          spy={true}
                          smooth={true}
                          duration={1000}
                        >
                          <span
                            className="txt"
                            onClick={() => setIsFormSubmitted(false)}
                          >
                            Back To Home
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <h3 className="title">Quick Enquiry Form</h3>

                    {/* <form id="my_form" onSubmit={(e) => submitForm(e)}>
                      <div className={"form-group focused"}>
                        <input type="text" required name="firstName" />
                        <label>First Name *</label>
                        <span className="focus-border"></span>
                      </div>
                      <div className={"form-group"}>
                        <input type="text" required name="lastName" />
                        <label>Last Name *</label>
                        <span className="focus-border"></span>
                      </div>
                      <div className={"form-group"}>
                        <input type="email" required name="email" />
                        <label>Email *</label>
                        <span className="focus-border"></span>
                      </div>
                      <div className={"form-group"} hidden>
                        <input type="text" name="mobile" value={"1234567890"} />
                        <label>Phone *</label>
                        <span className="focus-border"></span>
                      </div>
                      <div className={"form-group"}>
                        <textarea required name="message"></textarea>
                        <label>Enquiry *</label>
                        <span className="focus-border"></span>
                      </div>
                      <div className="form-group">
                        <div className="btns-box">
                          <button
                            type="submit"
                            className="theme-btn btn-style-one"
                          >
                            <span className="txt">Submit</span>
                          </button>
                        </div>
                      </div>
                    </form> */}
                    <HubspotForm
                      style={{textAlign:'center',height:400}}
                      portalId='22249194'
                      formId='3c7647e8-7f15-4fd9-bc86-5329145f127a'
                      onSubmit={() => console.log()}
                      onReady={(form) => console.log('Form ready!')}
                      loading={<div>Loading...</div>}
                    />
                  </>
                )}
                <div className="callto-action-wrapper text-margin-top">
                  <span className="text">Or call us now</span>
                  <span>
                    <i className="fas fa-phone-alt"></i>{" "}
                    <a href="tel:+919462908999">+91-946-290-8999</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
